const network = require('@/config/net.config')

export const getGuidVCode = function () {
    let localGuid = localStorage.getItem("guid");
    if (localGuid != "" && localGuid != null) {
        return localGuid;
    }
    let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
    localStorage.setItem("guid", guid);
    return guid;
}

export const setUserInfo = function (data) {
    localStorage.setItem("user", data);
}

export const getUserInfo = function (data) {
    localStorage.getItem("user");
}

export const getUploadUrl = function () {
    return network.apiUrl + "/api/upload/img";
}
export const getTinyImgUrl = function () {
    return network.apiUrl + "/api/upload/tinyImg";
}
/**
 * 获取验证码URL
 * @returns {string}
 */
export const getVCodeUrl = function () {
    return network.apiUrl + '/Home/GetVerify?guid=' + getGuidVCode() + '&v=' + Date();
}

export const getFileNameByUrl = function (url) {
    if (url == '' || url == undefined || url == null) {
        return;
    }
    let num = url.lastIndexOf('/') + 1
    let name = url.substring(num)
    return name;
}

export const timestampToTime = function (timestamp) {
    let date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = date.getDate() + ' ';
    let h = date.getHours() + ':';
    let m = date.getMinutes() + ':';
    let s = date.getSeconds();
    return Date.parse(Y + M + D + h + m + s);
}
export const timeToTimestamp = function (dt) {
    return Number(dt) / 1000;
}

export const setToken = function (token) {
    localStorage.setItem("token", token);
}
export const getToken = function (token) {
    return localStorage.getItem("token");
}

export const setLoginName = function (loginName) {
    localStorage.setItem("loginName", loginName);
}
export const getLoginName = function () {
    return localStorage.getItem("loginName");
}

export const removeUser = function () {
    localStorage.removeItem("loginName");
}




