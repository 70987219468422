import http from "@/utils/http";

//获取列表
export async function getList(data, pageIndex, pageSize) {
    return http.post('/api/sysMenu/getList?pageIndex=' + pageIndex + "&pageSize=" + pageSize, data)
}

//添加数据
export async function add(data) {
    return http.post('/api/sysMenu/add', data)
}

//修改数据
export async function update(data) {
    return http.post('/api/sysMenu/update', data)
}

//获取数据
export async function getModel(id) {
    return http.get('/api/sysMenu/getModel?id=' + id, {})
}

//删除数据 ids 数组[]
export async function deleteByIds(ids) {
    return http.get('/api/sysMenu/deleteByIds?ids=' + ids, {})
}

//获取树菜单
export async function getMenuTreeList() {
    return http.get('/api/sysMenu/getMenuTreeList', {})
}



