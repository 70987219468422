import {getUserInfo, login, logout} from '@/api/user'
import {
    getAccessToken,
    removeAccessToken,
    setAccessToken,
} from '@/utils/accessToken'
import {tokenName} from '@/config'

const state = () => ({
    accessToken: getAccessToken(),
    name: '',
    avatar: '',
    permissions: [],
})
const getters = {
    accessToken: (state) => state.accessToken,
    name: (state) => state.name,
    avatar: (state) => state.avatar,
    permissions: (state) => state.permissions,
}
const mutations = {
    setAccessToken(state, accessToken) {
        state.accessToken = accessToken
        setAccessToken(accessToken)
    },
    setName(state, name) {
        state.name = name
    },
    setAvatar(state, avatar) {
        state.avatar = avatar
    },
    setPermissions(state, permissions) {
        state.permissions = permissions
    },
}
const actions = {
    setPermissions({commit}, permissions) {
        commit('setPermissions', permissions)
    },
    async login({commit}, userInfo) {
        const {data} = await login(userInfo)
        const accessToken = data[tokenName]
        if (accessToken) {
            commit('setAccessToken', accessToken)
        } else {

        }
    },
    async getUserInfo({commit, state}) {
        const {data} = await getUserInfo(state.accessToken)
        if (!data) {
            return false
        }
        let {permissions, username, avatar} = data
        if (permissions && username && Array.isArray(permissions)) {
            commit('setPermissions', permissions)
            commit('setName', username)
            commit('setAvatar', avatar)
            return permissions
        } else {
            return false
        }
    },
    async logout({dispatch}) {
        await logout(state.accessToken)
        await dispatch('resetAccessToken')
    },
    resetAccessToken({commit}) {
        commit('setPermissions', [])
        commit('setAccessToken', '')
        removeAccessToken()
    },
}
export default {state, getters, mutations, actions}
