<template>
  <router-view/>
</template>
<script>
export default {
  name: 'App',
  components: {},
}
</script>

<style lang="scss">

$tableHeaderBg: #f5f7fa;

.clearfix:after {
  display: block;
  clear: both;
  content: "";
  visibility: hidden;
  height: 0;
}

.clearfix {
  zoom: 1
}

.gg-table .el-table__header tr,
.gg-table .el-table__header th {
  padding: 0;
  height: 40px;
}

.gg-table .el-table__body tr,
.gg-table .el-table__body td {
  padding: 0;
  height: 40px;
}


.m-list .el-pagination {
  background-color: white;
  padding: 10px 16px;
}

.m-list .handle-search {
  align-items: center;
  padding: 10px 10px 0px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 5px;
  margin-top: 5px;

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-col {
  }
}

.m-form .el-drawer__header {
  margin-bottom: unset;
}

.m-list .handle-action {
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 5px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(221, 222, 224, 0); /*滚动条的背景颜色*/
  border-radius: 3px /*滚动条的圆角宽度*/
;
}

::-webkit-scrollbar-track-piece {
  background-color: hsla(0, 0%, 100%, .5);
  border-radius: 100px /*滚动条的圆角宽度*/
;
}
</style>
