import http from "@/utils/http";

export async function login(data) {
    return http.post('/api/index/login', data)
}

export async function logout(data) {
    return http.post('/api/index/logout', data)
}

export async function getUserInfo(data) {
    return http.post('/api/sysadmin/getUserInfo', data)
}

