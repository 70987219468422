import http from "@/utils/http";

//获取列表
export async function getList(data, pageIndex, pageSize) {
    return http.post('/api/sysAdmin/getList?pageIndex=' + pageIndex + "&pageSize=" + pageSize, data)
}

//添加数据
export async function add(data) {
    return http.post('/api/sysAdmin/add', data)
}

//修改数据
export async function update(data) {
    return http.post('/api/sysAdmin/update', data)
}

//获取数据
export async function getModel(id) {
    return http.get('/api/sysAdmin/getModel?id=' + id, {})
}

//删除数据 ids 数组[]
export async function deleteByIds(ids) {
    return http.get('/api/sysAdmin/deleteByIds?ids=' + ids, {})
}

//登录
export async function login(data) {
    return http.post('/api/sysAdmin/login', data)
}

/**
 * 退出登录
 * @param token
 * @returns {Promise<unknown>}
 */
export async function logout(token) {
    return http.get('/api/sysAdmin/logout?token=' + token, {})
}
