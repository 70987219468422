import axios from 'axios'
import qs from 'qs'
import {getToken} from "@/utils/utils";

const network = require('@/config/net.config')

axios.defaults.baseURL = network.apiUrl;
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";

axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        console.log("网络请求失败，反馈给客服" + error)
    }
);

function request(url, params, method) {
    axios.defaults.headers.common["token"] = getToken();
    let data = method == "post" ? qs.stringify(params) : Object.assign(params)
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: url,
            data: data
        })
            .then(res => {
                if (res != null && res.data != null && res.data.status == 200) {
                    resolve(res.data)
                } else {
                    if (res != undefined && res.data != null) {
                        reject(res.data.msg || '系统错误');
                    } else {
                        reject('系统错误');
                    }
                }
            })
            .catch(err => {
                console.log(err);
                reject(err || '请求失败');
            });
    })
}

async function post(url, params) {
    return request(url, params, "post");
}

async function get(url, params) {
    return request(url, params, "get");
}

export default {post, get}
