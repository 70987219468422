
const state = () => ({
    username: 'setting',
    avatar: ''
})

const getters = {
    username: (state) => state.username,
    avatar: (state) => state.avatar
}

const mutations = {
    setUsername(state, data) {
        state.username = data
    },
    setAvatar(state, data) {
        state.avatar = data
    }

}

const actions = {
    getUserInfo({commit, state}) {
        commit('setUsername', "1")
        commit('setAvatar', "3")
        console.log(state)
    }

}
const modules={}

export default { state,getters,mutations,actions,modules}
