import {createRouter, createWebHashHistory} from 'vue-router'
import layout from '../layout/Index'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/single/login'),
    },
    {
        path: '/',
        component: layout,
        redirect: "/index",
        children: [
            {
                path: 'index',
                name: 'Index',
                component: () => import('../views/dashboard/Index'),
                meta: {
                    title: 'ISong',
                    auth: true,
                },
            },
            {
                path: 'userinfo/index',
                name: 'userInfo',
                component: () => import('../views/userInfo/Index'),
                meta: {
                    title: '用户列表',
                    auth: true,
                    navPath: ["用户管理", "用户列表"]
                },
            },
            {
                path: '/system/sysadmin',
                name: 'sysadmin',
                component: () => import('../views/system/sysAdmin/Index'),
                meta: {
                    title: '员工管理',
                    auth: true,
                    navPath: ["系统设置", "员工管理"]
                },
            },
            {
                path: '/system/sysmenu',
                name: 'sysmenu',
                component: () => import('../views/system/sysMenu/Index'),
                meta: {
                    title: '菜单管理',
                    auth: true,
                    navPath: ["系统设置", "菜单管理"]
                },
            },
            {
                path: '/system/sysactionbtn',
                name: 'sysactionbtn',
                component: () => import('../views/system/sysActionBtn/Index'),
                meta: {
                    title: '按钮管理',
                    auth: true,
                    navPath: ["系统设置", "按钮管理"]
                },
            },
            {
                path: '/system/sysrole',
                name: 'sysrole',
                component: () => import('../views/system/sysRole/Index'),
                meta: {
                    title: '角色管理',
                    auth: true,
                    navPath: ["系统设置", "角色管理"]
                },
            },
            {
                path: '/system/confsystem',
                name: 'confsystem',
                component: () => import('../views/system/sysConfSystem/Index'),
                meta: {
                    title: '系统配置',
                    auth: true,
                    navPath: ["系统设置", "系统配置"]
                },
            },
            {
                path: '/system/sysdictInfo',
                name: 'sysdictInfo',
                component: () => import('../views/system/sysDictInfo/Index'),
                meta: {
                    title: '字典管理',
                    auth: true,
                    navPath: ["系统设置", "字典管理"]
                },
            },
            {
                path: '/signup/index',
                name: 'signup',
                component: () => import('../views/signUp/Index'),
                meta: {
                    title: '报名列表',
                    auth: true,
                    navPath: ["报名管理", "报名列表"]
                },
            },
            {
                path: '/songCategory/index',
                name: 'songCategory',
                component: () => import('../views/songCategory/Index'),
                meta: {
                    title: '歌曲分类',
                    auth: true,
                    navPath: ["歌曲管理", "歌曲分类"]
                },
            },
            {
                path: '/songLibrary/index',
                name: 'songLibrary',
                component: () => import('../views/songLibrary/Index'),
                meta: {
                    title: '歌曲列表',
                    auth: true,
                    navPath: ["歌曲管理", "歌曲列表"]
                },
            },
            {
                path: '/userCode/index',
                name: 'userCode',
                component: () => import('../views/userCode/Index'),
                meta: {
                    title: '用户短信',
                    auth: true,
                    navPath: ["用户列表", "用户短信"]
                },
            },
            {
                path: '/advSpace/Index',
                name: 'advSpace',
                component: () => import('../views/advSpace/Index'),
                meta: {
                    title: '广告管理',
                    auth: true,
                    navPath: ["广告管理", "位置列表"]
                },
            },
            {
                path: '/adv/Index',
                name: 'adv',
                component: () => import('../views/adv/Index'),
                meta: {
                    title: '广告列表',
                    auth: true,
                    navPath: ["广告管理", "广告列表"]
                },
            },
            {
                path: '/articleClass/index',
                name: 'articleClass',
                component: () => import('../views/articleClass/Index'),
                meta: {
                    title: '内容分类',
                    auth: true,
                    navPath: ["内容管理", "内容分类"]
                },
            },
            {
                path: '/article/index',
                name: 'article',
                component: () => import('../views/article/Index'),
                meta: {
                    title: '内容列表',
                    auth: true,
                    navPath: ["内容管理", "内容列表"]
                },
            },
            {
                path: '/crowdfunding/index',
                name: 'crowdfunding',
                component: () => import('../views/crowdfunding/Index'),
                meta: {
                    title: '众筹列表',
                    auth: true,
                    navPath: ["众筹管理", "众筹列表"]
                },
            },
            {
                path: '/crowdfundingPrize/index',
                name: 'crowdfundingPrize',
                component: () => import('../views/crowdfundingPrize/Index'),
                meta: {
                    title: '众筹列表',
                    auth: true,
                    navPath: ["众筹管理", "众筹抽奖"]
                },
            },
            {
                path: '/crowdfundingOrder/index',
                name: 'crowdfundingOrder',
                component: () => import('../views/crowdfundingOrder/Index'),
                meta: {
                    title: '订单管理',
                    auth: true,
                    navPath: ["订单管理", "众筹订单"]
                },
            },
            {
                path: '/capitalFlow/index',
                name: 'capitalFlow',
                component: () => import('../views/capitalFlow/Index'),
                meta: {
                    title: '资金流水',
                    auth: true,
                    navPath: ["财务管理", "资金流水"]
                },
            },
            {
                path: '/userDeposit/index',
                name: 'userDeposit',
                component: () => import('../views/userDeposit/Index'),
                meta: {
                    title: '资金流水',
                    auth: true,
                    navPath: ["财务管理", "充值列表"]
                },
            },
            {
                path: '/userWithdrawal/index',
                name: 'userWithdrawal',
                component: () => import('../views/userWithdrawal/Index'),
                meta: {
                    title: '资金流水',
                    auth: true,
                    navPath: ["财务管理", "提现列表"]
                },
            },
            {
                path: '/userCertification/index',
                name: 'userCertification',
                component: () => import('../views/userCertification/Index'),
                meta: {
                    title: '实名认证',
                    auth: true,
                    navPath: ["用户管理", "实名认证"]
                },
            },
            {
                path: '/songClaim/index',
                name: 'songClaim',
                component: () => import('../views/songClaim/Index'),
                meta: {
                    title: '歌曲管理',
                    auth: true,
                    navPath: ["歌曲管理", "认领列表"]
                },
            },
            {
                path: '/crowdfundingLotteryUser/index',
                name: 'crowdfundingLotteryUser',
                component: () => import('../views/crowdfundingLotteryUser/Index'),
                meta: {
                    title: '中奖列表',
                    auth: true,
                    navPath: ["众筹管理", "中奖列表"]
                },
            },
            {
                path: '/auctionOrder/index',
                name: 'auctionOrder',
                component: () => import('../views/auctionOrder/Index'),
                meta: {
                    title: '拍卖订单',
                    auth: true,
                    navPath: ["订单管理", "拍卖订单"]
                },
            },
            {
                path: '/auctionProduct/index',
                name: 'auctionProduct',
                component: () => import('../views/auctionProduct/Index'),
                meta: {
                    title: '拍卖产品',
                    auth: true,
                    navPath: ["拍卖管理", "产品列表"]
                },
            },
            {
                path: '/rebateApply/index',
                name: 'rebateApply',
                component: () => import('../views/rebateApply/Index'),
                meta: {
                    title: '佣金管理',
                    auth: true,
                    navPath: ["佣金管理", "审核记录"]
                },
            },
            {
                path: '/rebateInfo/index',
                name: 'rebateInfo',
                component: () => import('../views/rebateInfo/Index'),
                meta: {
                    title: '佣金管理',
                    auth: true,
                    navPath: ["佣金管理", "佣金记录"]
                },
            },
            {
                path: '/taskConfig/index',
                name: 'taskConfig',
                component: () => import('../views/taskConfig/Index'),
                meta: {
                    title: '任务配置',
                    auth: true,
                    navPath: ["任务管理", "任务配置"]
                },
            },
            {
                path: '/taskRecord/index',
                name: 'taskRecord',
                component: () => import('../views/taskRecord/Index'),
                meta: {
                    title: '任务记录',
                    auth: true,
                    navPath: ["任务管理", "任务记录"]
                },
            },
            {
                path: '/bankInfo/index',
                name: 'bankInfo',
                component: () => import('../views/bankInfo/Index'),
                meta: {
                    title: '银行管理',
                    auth: true,
                    navPath: ["财务管理", "银行管理"]
                },
            },
            {
                path: '/userAddress/index',
                name: 'userAddress',
                component: () => import('../views/userAddress/Index'),
                meta: {
                    title: '用户地址',
                    auth: true,
                    navPath: ["用户管理", "用户地址"]
                },
            },
            {
                path: '/userBank/index',
                name: 'userBank',
                component: () => import('../views/userBank/Index'),
                meta: {
                    title: '银行管理',
                    auth: true,
                    navPath: ["用户管理", "银行管理"]
                },
            },
            {
                path: '/dayCount/index',
                name: 'dayCount',
                component: () => import('../views/dayCount/Index'),
                meta: {
                    title: '每日统计',
                    auth: true,
                    navPath: ["数据统计", "每日统计"]
                },
            },
            {
                path: '/userLoginLog/index',
                name: 'userLoginLog',
                component: () => import('../views/userLoginLog/Index'),
                meta: {
                    title: '登录日志',
                    auth: true,
                    navPath: ["用户管理", "登录日志"]
                },
            },
            {
                path: '/productService/index',
                name: 'productService',
                component: () => import('../views/productService/Index'),
                meta: {
                    title: '登录日志',
                    auth: true,
                    navPath: ["用户管理", "登录日志"]
                },
            },
            {
                path: '/videoClass/index',
                name: 'videoClass',
                component: () => import('../views/videoClass/Index'),
                meta: {
                    title: '视频课堂',
                    auth: true,
                    navPath: ["内容管理", "视频课堂"]
                },
            },
            {
                path: '/singerSettled/index',
                name: 'singerSettled',
                component: () => import('../views/singerSettled/Index'),
                meta: {
                    title: '歌手入住',
                    auth: true,
                    navPath: ["报名管理", "歌手入住"]
                },
            },
            {
                path: '/creatorSettled/index',
                name: 'creatorSettled',
                component: () => import('../views/creatorSettled/Index'),
                meta: {
                    title: '创作人表',
                    auth: true,
                    navPath: ["报名管理", "创作人表"]
                },
            },
            {
                path: '/userWork/index',
                name: 'userWork',
                component: () => import('../views/userWork/Index'),
                meta: {
                    title: '用户作品',
                    auth: true,
                    navPath: ["歌曲管理", "用户作品"]
                },
            },
            {
                path: '/productSong/index',
                name: 'productSong',
                component: () => import('../views/productSong/Index'),
                meta: {
                    title: '用户作品',
                    auth: true,
                    navPath: ["歌曲管理", "用户作品"]
                },
            },
            {
                path: '/songRanking/index',
                name: 'songRanking',
                component: () => import('../views/songRanking/Index'),
                meta: {
                    title: '用户作品',
                    auth: true,
                    navPath: ["歌曲管理", "用户作品"]
                },
            },
            {
                path: '/orderInfo/index',
                name: 'orderInfo',
                component: () => import('../views/orderInfo/Index'),
                meta: {
                    title: '音乐订单',
                    auth: true,
                    navPath: ["订单管理", "音乐订单"]
                },
            },
            {
                path: '/product/index',
                name: 'product',
                component: () => import('../views/product/Index'),
                meta: {
                    title: '实物产品',
                    auth: true,
                    navPath: ["产品管理", "实物产品"]
                },
            },
            {
                path: '/rechargeAward/index',
                name: 'rechargeAward',
                component: () => import('../views/rechargeAward/Index'),
                meta: {
                    title: '充值奖励',
                    auth: true,
                    navPath: ["系统设置", "充值奖励"]
                },
            },
            {
                path: '/orderInfo/index2',
                name: 'orderInfo2',
                component: () => import('../views/orderInfo/Index2'),
                meta: {
                    title: '实物订单',
                    auth: true,
                    navPath: ["订单管理", "实物订单"]
                },
            },
            {
                path: '/userRankConfig/index',
                name: 'userRankConfig',
                component: () => import('../views/userRankConfig/Index'),
                meta: {
                    title: '用户等级',
                    auth: true,
                    navPath: ["系统设置", "用户等级"]
                },
            },
            {
                path: '/turntableConfig/index',
                name: 'turntableConfig',
                component: () => import('../views/turntableConfig/Index'),
                meta: {
                    title: '用户等级',
                    auth: true,
                    navPath: ["系统设置", "转盘配置"]
                },
            },
            {
                path: '/rechargeDiscount/index',
                name: 'rechargeDiscount',
                component: () => import('../views/rechargeDiscount/Index'),
                meta: {
                    title: '充值折扣',
                    auth: true,
                    navPath: ["系统设置", "充值折扣"]
                },
            },
            {
                path: '/giftInfo/index',
                name: 'giftInfo',
                component: () => import('../views/giftInfo/Index'),
                meta: {
                    title: '礼物管理',
                    auth: true,
                    navPath: ["直播管理", "礼物管理"]
                },
            },
            {
                path: '/giftUser/index',
                name: 'giftUser',
                component: () => import('../views/giftUser/Index'),
                meta: {
                    title: '背包管理',
                    auth: true,
                    navPath: ["直播管理", "背包管理"]
                },
            },
            {
                path: '/voteSignUp/index',
                name: 'voteSignUp',
                component: () => import('../views/voteSignUp/Index'),
                meta: {
                    title: '投票活动报名',
                    auth: true,
                    navPath: ["投票管理", "投票活动报名"]
                },
            },
            {
                path: '/votePayConfig/index',
                name: 'votePayConfig',
                component: () => import('../views/votePayConfig/Index'),
                meta: {
                    title: '投票付费设置',
                    auth: true,
                    navPath: ["投票管理", "投票付费设置"]
                },
            },
            {
                path: '/voteCity/index',
                name: 'voteCity',
                component: () => import('../views/voteCity/Index'),
                meta: {
                    title: '投票活动城市设置',
                    auth: true,
                    navPath: ["投票管理", "投票活动设置"]
                },
            },
            {
                path: '/voteArea/index',
                name: 'voteArea',
                component: () => import('../views/voteArea/Index'),
                meta: {
                    title: '投票活动地区设置',
                    auth: true,
                    navPath: ["投票管理", "投票活动设置"]
                },
            },
            {
                path: '/voteRecord/index',
                name: 'voteRecord',
                component: () => import('../views/voteRecord/Index'),
                meta: {
                    title: '投票记录',
                    auth: true,
                    navPath: ["投票管理", "投票记录"]
                },
            },
            {
                path: '/vote/index',
                name: 'vote',
                component: () => import('../views/vote/Index'),
                meta: {
                    title: '投票活动',
                    auth: true,
                    navPath: ["投票管理", "投票活动"]
                },
            },
            {
                path: '/voteConfig/index',
                name: 'voteConfig',
                component: () => import('../views/voteConfig/Index'),
                meta: {
                    title: '投票设置',
                    auth: true,
                    navPath: ["投票管理", "投票设置"]
                },
            },
            {
                path: '/productIntegral/index',
                name: 'productIntegral',
                component: () => import('../views/productIntegral/Index'),
                meta: {
                    title: '商品管理',
                    auth: true,
                    navPath: ["商品管理", "积分商品"]
                },
            },
            {
                path: '/voteActivity/index',
                name: 'voteActivity',
                component: () => import('../views/voteActivity/Index'),
                meta: {
                    title: '活动管理',
                    auth: true,
                    navPath: ["活动管理", "活动列表"]
                },
            },
            {
                path: '/voteCate/index',
                name: 'voteCate',
                component: () => import('../views/voteCate/Index'),
                meta: {
                    title: '活动分类',
                    auth: true,
                    navPath: ["活动管理", "活动分类"]
                },
            },
            {
                path: '/merchants/index',
                name: 'merchants',
                component: () => import('../views/merchants/Index'),
                meta: {
                    title: '入驻平台',
                    auth: true,
                    navPath: ["入驻平台", "入驻列表"]
                },
            },

        ],
    },
    {
        path: '/401',
        name: '401',
        component: () => import('../views/single/error/401'),
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/single/error/404'),
    },
    {
        path: '/500',
        name: '500',
        component: () => import('../views/single/error/500'),
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    scrollBehavior: () => ({
        y: 0,
    }),
    routes
})


export function resetRouter() {

}

export default router
