const state = () => ({
    tableHeight: 500,
})
const getters = {
    tableHeight: (state) => state.tableHeight,
}

const mutations = {
    setTableHeight(state, data) {
        state.tableHeight = data
    }
}

const actions = {
    getTableHeight({commit, state}) {
        commit('tableHeight', 500)
        console.log(state)
    }

}
const modules = {}

export default {state, getters, mutations, actions, modules}
