import {createStore} from 'vuex'
import user from './modules/user'
import setting from './modules/setting'
import table from "@/store/modules/table";

const modules = {user, setting, table}

Object.keys(modules).forEach((key) => {
    modules[key]['namespaced'] = true
})
const store = new createStore({
    modules
})
export default store