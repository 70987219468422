import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {getToken} from './utils/utils'
import installElementPlus from './plugins/element'

const app = createApp(App)
installElementPlus(app)

router.beforeEach((to, from, next) => {
    if (to.matched.some(m => m.meta["auth"])) {
        let token = getToken();
        if (token == null || token == '' || token == undefined) {
            next({path: '/login'})
        } else {
            next();
        }
    } else {
        next();
    }
})

app.use(store).use(router).mount('#app')