const theme = {
    //是否国定头部 固定fixed 不固定noFixed
    header: 'fixed',
    //横纵布局 horizontal vertical
    layout: 'vertical',
    //是否开启主题配置按钮
    themeBar: true,
    //是否显示多标签页
    tabsBar: true,
}
module.exports = theme
